import React from "react"
import {Link} from 'gatsby'
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import FeaturesArea from '../containers/index-infotechno/features-area'
import ContactArea from '../containers/global/contact-area/contact-four'
import Accordian from '../components/accordian/accordian'
import AboutArea from '../containers/index-infotechno/pricing-area'
import AboutServiceWrap from '../containers/index-infotechno/pricing-wrap'
import MailchimSignup from '../components/mailchim-signup'

const ITServicePage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Services" />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Services & Pricing"
    />
    <main className="site-wrapper-reveal">
        <FeaturesArea />
        <AboutServiceWrap>
          <AboutArea /> 
        </AboutServiceWrap>
        <div class="container accordian-container">
          <h3 class="text-center">FAQ's</h3>
          <Accordian 
            questions={[
              {
                title: "What is a professional organiser?",
                text: "POs work directly with clients, offering guidance, encouragement, support, strategies and practical help to organise various aspects of their homes or offices – and their lives. Individual POs offer a range of expert services including: decluttering, storage solutions, admin and paperwork, time management, coaching, design and/or home-staging.",
              },
              {
                title: "Will you make me throw stuff away? ",
                text: "We will never, ever force you to get rid of anything that you do not want to part with.",
              },
              {
                title: "Should I clean up the area before the session?",
                text: "You don’t need to deep clean before a session unless it may hinder us on the day, we can lightly wipe surfaces as we go on the day as necessary.",
              },
              {
                title: "Do I need to be involved in a session?",
                text: "You will need to be present during the decluttering phase of the session to approve what stays and where things move to.",
              },
              {
                title: "What do you charge?",
                text: "Our rates start at £35 per hour, and each session is a minimum of 2 hours.",
              },
              {
                title: "Are you insured?",
                text: "Yes, we are fully covered by public liability and private indemnity Insurance",
              },
              {
                title: "Will you take away the declutter items on the day? ",
                text: "I will take away 2 bags on the day. Any extra bags will incur a charge of £15.",
              },
              {
                title: "Do you sell gift vouchers?",
                text: "Yes, they are available upon request. Please call or email.",
              },
              {
                title: "What is your covid policy?",
                text: <div><Link to="/covid-policy">Click here</Link> for our policy</div>,
              },
              {
                title: "Terms & Conditions",
                text: <div>For our full terms and conditions, <Link to="/terms">click here</Link></div>,
              },
            ]}
          />
        </div>
        <ContactArea/>
    </main>
    <MailchimSignup />
    <Footer/>
  </Layout>
)
 
export default ITServicePage
 